@import "variables";

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
}

* {
  font-family: LoewNextArabic, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  height: 100%;
  font-family: LoewNextArabic, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 1.4rem;
  line-height: 1.87rem;
  background-color: #E5E5E5;
  color: $color-dark-blue;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  padding: 0;
  font-weight: normal;
}

ol, ul {
  padding-left: 3rem !important;
  padding-right: 3rem !important;

  li {
    margin-bottom: 1rem;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}
