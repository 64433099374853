
#root {
  width: 100%;
  min-height: 100%;
  display: flex;
}

.form-floating {
  > .form-label {
    line-height: 1;
    color: rgba(#1C1C1C, 0.2);

    [dir='rtl'] & {
      transform-origin: 100% 0;
      right: 0;
      left: auto;
    }
  }
}

body.activePopUp {
  header {
    display: none;
  }

  .hideWhenPopup {
    display: none;
  }
}

/* custom bootstrap ratio for animation studio */
.ratio-3x4 {
  --bs-aspect-ratio: 111%;
}
