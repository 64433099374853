.auth0-lock-widget-container {
   border-radius: 3rem !important;
   overflow: hidden;
}

.auth0-lock-body-content,
.auth0-lock.auth0-lock .auth0-lock-cred-pane {
   background-color: #095DBE !important;
}

.auth0-lock.auth0-lock .auth0-lock-header {
   display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-tabs {
   background-color: #095DBE !important;
}
.auth0-lock-tabs li a,
.auth0-lock-tabs li span {
   font-family: LoewNextArabic, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   padding: 20px 10px !important;
   font-size: 2rem;
   line-height: 3.4rem;
   font-weight: 900 !important;
   text-transform: uppercase;
   text-shadow: 1px 1px 2px rgb(0 0 0 / 30%)
}
.auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current{
   box-shadow: 0 1px 0 0 #ffffff !important;
}
.auth0-lock-tabs li span {
   color: white !important;
}
.auth0-lock-tabs li a {
   color: #0096FF !important;
}
.auth0-lock.auth0-lock .auth0-lock-submit {
   background-color: #FAA000 !important;
   color: white;
   box-shadow: 0.2rem 0.2rem 0.6rem #00000080;
   font-weight: 900;
   border-radius: 6rem !important;
   font-size: 2rem !important;
   line-height: 3.4rem !important;
   margin: 1rem;
   width: auto !important;
   padding: 9px !important;
}

.auth0-lock.auth0-lock .auth0-lock-form p,
.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link {
   color: white !important;
   font-size: 1.6rem !important;
}
.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link {
   text-decoration: underline;
}
.auth0-lock-input {
   border-top-right-radius: 6rem !important;
   border-bottom-right-radius: 6rem !important;
   font-size: 1.6rem !important;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap {
   border-radius: 6rem !important;
   width: auto !important;
   overflow: hidden;
   box-shadow: 0.2rem 0.2rem 0.6rem #00000080 !important;
}


.auth0-lock.auth0-lock .auth0-lock-close-button {
   color: white !important;
   background-color: rgb(255, 0, 95) !important;
   font-weight: 900;
   border: 12px solid rgb(255, 0, 95) !important;
   height:20px!important;
   width: 20px!important;
   top: -20px!important;
   right: -20px!important;
}

.auth0-lock.auth0-lock .auth0-lock-close-button polygon {
   fill: white !important;
   stroke-width: 16px !important;
   stroke: white !important;
}

.auth0-lock-error-invalid-hint {
   text-shadow: 1px 1px 2px rgb(0 0 0 / 30%) !important;
   font-size: 1.3rem !important;
   color: white;
}

@media (max-width: 481px) {
   .auth0-lock-widget-container {
      border-radius: 0 !important;
      overflow: hidden;
   }

   .auth0-lock.auth0-lock .auth0-lock-close-button {
      top: 10px !important;
      right: 10px !important;
   }

   .auth0-lock.auth0-lock .auth0-lock-header {
      display: block !important;
      visibility: hidden !important;
      max-height: 7rem !important;
   }

   .auth0-lock.auth0-lock .auth0-lock-submit {
      margin-bottom: 30px !important;
   }
}
