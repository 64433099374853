@import "~@ilmiworld/ui/src/styles/variables";

$header_height: 86px;
$footer_height: 100px;

.main_wrap{
    height: calc(100vh - ($header_height + $footer_height));
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/*
._canvas{
    height: 100%;
    object-fit: contain;
    width: 100%;
}
*/

._canvas{
    position: absolute;
}
