@import "~@ilmiworld/ui/src/styles/variables";

.app {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $color-dark-blue;
}
