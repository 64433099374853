@import "~@ilmiworld/ui/src/styles/variables";

$backgroundColor: $color-orange;
$outerPieWidth: 4.6rem;
$outerPieColor: $color-orange;
$innerPieWidth: 4.6rem;
$innerPieColor: $color-white;

.container {
  width: 4.6rem;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}

.outerPie {
  width: $outerPieWidth;
  height: $outerPieWidth;
  background-color: $outerPieColor;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;

  .innerPie {
    width: $innerPieWidth;
    height: $innerPieWidth;
    border-radius: 50%;
    background: $outerPieColor;
    background-image: linear-gradient(
                    to right,
                    transparent 50%,
                    $innerPieColor 0
    );

    .counter {
      content: '';
      display: block;
      margin-left: 50%;
      height: 100%;
      border-radius: 0 100% 100% 0 / 50%;
      background-color: inherit;
      transform-origin: left;
      transform: rotate(.0turn);

      &.under {
        background: $outerPieColor;
        transform: rotate(.4turn);
      }

      &.over {
        background: $innerPieColor;
        transform: rotate(.4turn);
      }

    }
  }
}
