body {
  &.full-height {
    touch-action: none;

    #root {
      height: 100%;
    }
  }
}

#root {
  width: 100%;
  min-height: 100%;
  display: flex;
}
