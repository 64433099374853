@import "~@ilmiworld/ui/src/styles/variables";

.container {
  position: relative;
  .star1 {
    position: absolute;
    top: -9rem;
    left: -4rem;
  }
  .star2 {
    position: absolute;
    top: -2rem;
    left: 2rem;
  }
  .star3 {
    position: absolute;
    top: -2rem;
    left: 12rem;
  }
  .star4 {
    position: absolute;
    top: -9rem;
    left: 18rem;
  }

}
