@import "variables";
@import "~bootstrap/scss/mixins";

h1 {
  font-weight: 700;
  @include font-size(5.6rem);
  line-height: 1.2;
  text-shadow: $text-shadow;
  text-transform: uppercase;
}
h2 {
  font-weight: 700;
  @include font-size(5.6rem);
  line-height: 1.2;
  text-shadow: $text-shadow;
  text-transform: uppercase;
}
h3 {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1.2;
  text-shadow: $text-shadow;
  text-transform: uppercase;
}

blockquote,
li,
p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  text-shadow: $text-shadow;
  color: $color-white;

  &::marker {
    font-weight: 900;
  }
}

a {
  color: $color-dark-blue;
  font-size: 1.4rem;

  &:hover,
  &:active {
    color: $color-blue-light;
  }
}
