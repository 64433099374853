@import "~@ilmiworld/ui/src/styles/variables";
@import "~@ilmiworld/ui/src/styles/animations";
@import "~bootstrap/scss/mixins";

.scoreModalContainer {
  @include padding-top(2rem);
  margin-bottom: -23rem;
}

.scoreModalContent {
  position: relative;
  height: 235px;
  //max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 3rem;

  .image {
    position: absolute;
    display: block;
    z-index: -1;
    top: -34rem;
    width: auto;
    transform: scale(0);
    transform-origin: 85% 90%;
    animation: scaleIn 300ms ease-in-out 500ms forwards;
  }

  .stars {
    position: absolute;
    transform: translateX(-50%);
    top: -3rem;
    left: 50%;
    width: 24rem;
  }

  .title {
    position: relative;
    @include font-size(7rem);
    @include margin-bottom(0rem);
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 0 0.5rem rgba(black, 0.5);
    white-space: pre-wrap;
    transform: scale(0);
    animation: scaleIn 300ms ease-in-out 600ms forwards;
    text-align: center;
  }
  .subtitle {
    text-align: center;
    @include font-size(2rem);
    @include margin-top(1rem);
    text-shadow: 0 0 0.5rem rgba(black, 0.5);
    transform: scale(0);
    animation: scaleIn 300ms ease-in-out 600ms forwards;
  }
}

.background {
  position: fixed;
  z-index: -2;
  left: 0;
  right: 0;
  height: 235px;
  background: $gradient-yellow-end;
  background: linear-gradient(0deg, $gradient-pink-start 0%, $gradient-yellow-end 100%);
  transform: scaleX(0);
  animation: scaleXIn 300ms linear 200ms forwards;

  &.gameOver {
    background: $gradient-blue-end;
    background: linear-gradient(190deg, $gradient-pink-start 0%, $gradient-blue-end 100%);
  }
}


@media all and (max-width: map-get($grid-breakpoints, lg)) {
  .scoreModalContent {
    height: 17rem;
  }
  .background {
    height: 20rem;
  }
}


@media all and (max-width: map-get($grid-breakpoints, md)) {
  .scoreModalContent {
    height: 17rem;
  }
  .background {
    height: 17rem;
  }
}
