@import "variables";

@mixin readabilityGradientBlack($negativeMarginX: 0, $gradientOffsetTop: 0%) {
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: -$negativeMarginX;
    left: -$negativeMarginX;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) $gradientOffsetTop, rgba(0,0,0,0.3) 100%);
    z-index: -1;
  }
}

@mixin customScrollbars() {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: $color-blue-light;
    //border-radius: 10px * .5;

    &:hover {
      background: $color-blue;
    }
  }
  &::-webkit-scrollbar-track {
    background: rgba(white, 0.4);
    //border-radius: 10px * .5;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $color-blue-light rgba(white, 0.3);
}
