@import "~bootstrap/scss/mixins";
@import "~@ilmiworld/ui/src/styles/variables";
@import "src/components/Header/Header.variables";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .resultModalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $header-padding;
    position: relative;
  }
  .resultModalBody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .resultModalFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include padding(3rem);
    position: relative;
  }

  .scoreExplanation {
    color: $color-white;
    @include font-size(3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3rem;
    margin-bottom: 3rem;

    border-bottom: 1px solid $color-white;

    .multipliers {
      font-weight: 900;
      color: $color-orange;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        margin: 0 2rem 0 3rem;

        [dir='rtl'] & {
          margin:  0 3rem 0 2rem;
        }
      }
      img:first-child {
        margin: 0 2rem 0 0;

        [dir='rtl'] & {
          margin: 0 0 0 2rem;
        }
      }
    }

  }
  .scoreTitle {
    color: $color-white;
    @include font-size(3rem);
    font-weight: 700;
    margin: 0;
  }
  .scoreExplanationModalFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @include padding(3rem);
    position: relative;
  }
}
