@import "~@ilmiworld/ui/src/styles/variables";
@import "~bootstrap/scss/mixins";

$INSTRUCTIONS_CAROUSEL_HEIGHT: 45rem;

.closeButtonContainer {
  position: absolute;
  right: 4rem;
  top: -3rem;
  z-index: 1056;
}

.dots {
  padding: 0;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  list-style-type: none;
  white-space: nowrap;

  &.hide {
    display: none;
  }

  li {
    display: inline-block;
    height: 1.2rem !important;
    width: 1.2rem !important;
    border: none !important;
    border-radius: 50% !important;
    flex-shrink: 0 !important;
    background-color: $color-white;
    margin-left: 1rem;
    box-shadow: 1px 1px 2px #0000004D;
    opacity: 0.5;
    transition: opacity 0.3s ease-in;

    &.active {
      opacity: 1;
    }
  }
}

.carousel {
  position: relative;
  min-height: $INSTRUCTIONS_CAROUSEL_HEIGHT;
  background: $color-blue;

  .carouselInner {
    z-index: 3;
    min-height: $INSTRUCTIONS_CAROUSEL_HEIGHT;

    .slide,
    .startSlide {
      position: relative;
      counter-increment: item;
      color: $color-white;
      min-height: $INSTRUCTIONS_CAROUSEL_HEIGHT;
      padding: 4rem;

      h1,
      p {
        text-align: center;
        margin-bottom: 3rem;
      }

      .instructionMascot {
        position: absolute;
        width: 23rem;
        bottom: -4rem;
        right: 0;
        pointer-events: none;
        z-index: -1;
        @include media-breakpoint-up(lg) {
          position: relative;
          width: 100%;
        }
      }
    }

    .startSlide {
      justify-content: start;
    }
  }

  .nextButton,
  .previousButton {
    width: 4rem;
    z-index: 10;

    &.hide {
      display: none;
    }
  }

  &.rtl {
    direction: rtl;

    :global(.carousel-item-next:not(.carousel-item-start)),
    :global(.active.carousel-item-end) {
      transform: translateX(-100%) !important;
    }

    :global(.carousel-item-prev:not(.carousel-item-end)),
    :global(.active.carousel-item-start) {
      transform: translateX(100%) !important;
    }

    :global(.carousel-control-prev) {
      left: auto;
      right: 0;
    }
    :global(.carousel-control-prev-icon) {
      transform: scaleX(-1);
    }

    :global(.carousel-control-next) {
      right: auto;
      left: 0;
    }
    :global(.carousel-control-next-icon) {
      transform: scaleX(-1);
    }
  }
}

@media all and (max-height: 600px) {
  .closeButtonContainer {
    top: 0.5rem;
    right: 0.8rem;
  }
}

@media all and (max-width: map-get($grid-breakpoints, xsm)) {
  .carousel {
    .carouselInner {
      .slide,
      .startSlide {
        padding: 3rem 2em 3rem 2rem;
      }
    }
  }
}

