
@font-face {
  font-family: 'LoewNextArabic';
  src: url(../assets/fonts/LoewNextArabic-Medium.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LoewNextArabic';
  src: url(../assets/fonts/LoewNextArabic-Heavy.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LoewNextArabic';
  src: url(../assets/fonts/LoewNextArabic-Black.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
}
