@import "~@ilmiworld/ui/src/styles/variables";

.irrigation_footer {
    height: 100px;
    width: 100%;
    background: $color-blue-dark;
    user-select: none;
}

.gpos{
    position: absolute;
    top:0; left:0;
    width: 100%; height: 100%;
}

.ifoot_wrap{
    width:100%;
    display: flex;
    align-items: center;
}

.ifoot_wrap_btn{
    padding: 20px;
    position: relative;
}
.ifoot_reset_disable{
    background: $color-blue-dark;
    opacity: 0.5;
}
.ifoot_basin_wrap{
    width:100%;
    display: flex;
    justify-content: flex-end;
}
.basin_img_wrap{
    position: relative;
}

.ifoot_num_txt{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    font-weight: bold;

    top: -2px;
    left:15px;
}


.clip_water{
    // clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
}