@import '~bootstrap/scss/mixins';
@import '../../styles/variables';

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 2rem;
  line-height: 3.4rem;
  min-height: $button-size;
  min-width: $button-size;
  border-radius: calc(#{$button-size});
  padding: 1rem calc(#{$button-size} / 2);
  background-color: $color-blue-light;
  color: white;
  transition: all 200ms;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(#000000, 0.3);
  text-decoration: none;
  box-shadow: $default-shadow;
  transform-origin: center center;
  user-select: none;

  &.fullWidth {
    width: 100%;
  }

  &.whiteSpacePre {
    white-space: pre;
  }

  &.hasIcon {
    justify-content: space-between;
    padding-left: calc(#{$button-size} / 2);
    padding-right: 0;

    [dir='rtl'] & {
      padding-right: calc(#{$button-size} / 2);
      padding-left: 0;
    }
  }

  &.small {
    min-height: $button-size-small;
    min-width: $button-size-small;
    border-radius: calc(#{$button-size-small});
    font-size: 1.6rem;
    padding: 0 calc(#{$button-size-small} / 2);

    &.hasIcon {
      padding-left: calc(#{$button-size-small} / 2);

      [dir='rtl'] & {
        padding-right: calc(#{$button-size-small} / 2);
      }
    }
  }

  &.extraSmall {
    min-height: $button-size-extra-small;
    min-width: $button-size-extra-small;
    border-radius: calc(#{$button-size-extra-small});
    font-size: 1.2rem;
    padding: 0 calc(#{$button-size-extra-small} / 2);

    &.hasIcon {
      padding-left: calc(#{$button-size-extra-small} / 2);

      [dir='rtl'] & {
        padding-right: calc(#{$button-size-extra-small} / 2);
      }
    }
  }

  &.large {
    min-height: $button-size-large;
    min-width: $button-size-large;
    border-radius: calc(#{$button-size-large});
    font-size: 3rem;

    padding: 0 calc(#{$button-size-large} / 2);

    &.hasIcon {
      padding-left: calc(#{$button-size-large} / 2);

      [dir='rtl'] & {
        padding-right: calc(#{$button-size-large} / 2);
      }
    }
  }

  &.extraLarge {
    min-height: $button-size-extra-large;
    min-width: $button-size-extra-large;
    border-radius: calc(#{$button-size-extra-large});
    font-size: 3rem;

    padding: 0 calc(#{$button-size-extra-large} / 2);

    &.hasIcon {
      padding-left: calc(#{$button-size-extra-large} / 2);

      [dir='rtl'] & {
        padding-right: calc(#{$button-size-extra-large} / 2);
      }
    }
  }

  &.noPadding {
    padding: 0;
  }

  &.isIconButton {
    padding: 0;
    height: $button-size;
    width: $button-size;

    &.small {
      height: $button-size-small;
      width: $button-size-small;
    }
    &.extraSmall {
      height: $button-size-extra-small;
      width: $button-size-extra-small;
    }
    &.large {
      height: $button-size-large;
      width: $button-size-large;
    }
  }

  &:hover:not(:disabled):not(.disabled) {
    background-color: $color-orange;
    color: white;
  }

  &.orange {
    background-color: $color-orange;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $color-blue-light;
    }
  }

  &.darkBlue {
    background-color: $color-blue-dark;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $color-blue-light;
    }
  }

  &.darkerBlue {
    background-color: $color-blue-darker;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $color-blue-darker;
    }
  }

  &.green {
    background-color: $color-green-bright;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $color-green-dark;
    }
  }

  &.red {
    background-color: $color-red;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $color-orange;
    }
  }

  &.redBlue {
    background-color: transparent;
    background: $linear-gradient-blue-red;

    &:hover:not(:disabled):not(.disabled) {
      background: $main-linear-gradient;
    }
  }

  &.white {
    background-color: $color-white;
    color: $color-dark-blue;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $gradient-white-start;
    }
  }

  &.noColor {
    background-color: transparent;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    &.small .content {
      border-width: 0.1rem;
    }

    &.large .content {
      border-width: 0.3rem;
    }

    &:hover:not(:disabled):not(.disabled) {
      color: $color-blue-dark;
      background-color: transparent;
      text-shadow: none;
      box-shadow: none;

      &.underlined .content {
        border-bottom-color: $color-blue-dark;
      }
    }
  }

  &.underlined {
    .content {
      transition: border-bottom-color 200ms;
      border-bottom: 0.2rem solid $color-white;
    }
  }

  &:disabled,
  &.disabled {
    cursor: default;
    box-shadow: none;
    .content,
    .iconContainer {
      opacity: 0.6;
    }
  }
  &.square {
    border-radius: 0.5rem;
  }

  &.responsive {
  }
}

.iconContainer {
  margin-left: 2rem;
  margin-right: 2rem;
}

@media all and (min-width: map-get($grid-breakpoints, sm)) {
  .button {
    /* A large IconButton on a iPad screen */
    &.responsive {
      &.small {
        min-height: $button-size-large;
        min-width: $button-size-large;
        border-radius: calc(#{$button-size-large});
        font-size: 1.6rem;
        padding: 0 calc(#{$button-size-large} / 2);

        &.isIconButton {
          padding: 0;
          height: $button-size;
          width: $button-size;

          img {
            width: 40px;
          }
        }
      }
    }
    &.responsive {
      &.large {
        min-height: $button-size-extra-large;
        min-width: $button-size-extra-large;
        border-radius: calc(#{$button-size-extra-large});
        font-size: 1.6rem;
        padding: 0 calc(#{$button-size-extra-large} / 2);

        &.isIconButton {
          padding: 0;
          height: $button-size-large;
          width: $button-size-large;

          img {
            width: 55px;
          }
        }
      }
    }
  }
}
