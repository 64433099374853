@import "~@ilmiworld/ui/src/styles/variables";
@import "~@ilmiworld/ui/src/styles/animations";
@import "~bootstrap/scss/mixins";
@import "../Header/Header.variables";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .feedbackModalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $header-padding;
  }
  .feedbackModalBody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .feedbackModalFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $header-padding;
    position: relative;

    .assetContainer {
      flex-grow: 1;

      .image {
        position: absolute;
        bottom: 0;
        left: 3rem;
        height: 27rem;
        transform: scaleX(-1);

        [dir='rtl'] & {
          transform: scaleX(1);
          right: 2rem;
          left: auto;
        }
      }
    }
  }
}

.feedbackModalContainer {
  @include padding-top(2rem);
  width: 100%;
}

.feedbackModalContent {
  position: relative;
  padding: 3rem;
  background: $color-green-bright;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  justify-content: center;

  &.red {
    background: $color-red;
  }

  .title {
    position: relative;
    z-index: 1;
    font-size: 7.5rem;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 0 0.5rem rgba(black, 0.5);
    white-space: pre-wrap;
    transform: scale(0);
    animation: scaleIn 300ms ease-in-out 600ms forwards;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  p{
    text-align: center;
  }

  .image {
    width: 100%;
    z-index: 0;
    margin-top: -5rem;
    right: -5rem;
    max-width: 38rem;
    transform: scale(0);
    transform-origin: 85% 90%;
    animation: scaleIn 300ms ease-in-out 500ms forwards;

    [dir='rtl'] & {
      left: -5rem;
      right: auto;
    }
  }
}


@media all and (max-width: map-get($grid-breakpoints, lg)) {
  .feedbackModalContent {
    .title {
      font-size: 5rem;
    }
  }
}


@media all and (max-width: map-get($grid-breakpoints, md)) {
  .feedbackModalContent {
    .title {
      font-size: 4rem;
    }
    .image {
      margin-top: -3rem;
    }
  }
}

@media all and (max-width: map-get($grid-breakpoints, xsm)) {
  .feedbackModalContent {
    padding: 3rem 2em 3rem 2rem;
  }
  .container {
    .feedbackModalFooter {
      .assetContainer {
        .image {
          left: 0rem;
          height: 20rem;
        }
      }
    }
  }
}
