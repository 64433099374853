// Sets the base to 20px same as the default 1.25 when the base rem is 16px
$rfs-base-font-size: 2rem;
// Fixes the base rem value in the calculations to match changed html value
$rfs-rem-value: 10;

$color-white: rgba(255, 255, 255);
$color-white-transparant: rgba(255, 255, 255, 0.5);
$color-black: rgba(0, 0, 0, 0.51);
$color-real-black: #1C1C1C;

$color-sand-light: #D7CEBC;
$color-sand: #D4C594;
$color-sand-dark: #AB9258;

$color-green: #00b400;
$color-green-dark: #007E3D;
$color-green-bright: #00B400;
$color-blue: #006DC4;
$color-blue-light: #0096FF;
$color-blue-dark: #095DBE;
$color-blue-darker: #223767;
$color-dark-blue: #302F47;
$color-blue-transparent: rgba(34, 55, 103, 0.9);
$color-dark-blue-transparent: rgba(48, 47, 71, 0.9);
$color-red: #ff005f;
$color-yellow: #FFCC14;
$color-orange: #FAA000;

$theme-colors: (
        "primary":    $color-blue,
        "secondary":  $color-green-bright,
        "success":    $color-green-bright,
        "danger":     $color-red,
        "light":      $color-white,
);

$component-green-color: $color-green-bright;
$component-red-color: $color-red;
$component-blue-color: $color-blue-light;
$component-blue-dark-color: $color-blue-dark;
$component-orange-color: $color-orange;

$content-background-color: $color-real-black;

$gradient-content-start: #1460bb;
$gradient-content-end: #08305f;

$gradient-green-start: #008B12;
$gradient-green-end: $color-green-bright;

$gradient-teal-start: #189779;
$gradient-teal-end: #68BDC4;

$gradient-blue-start: #008EFF;
$gradient-blue-end: #0739B9;

$gradient-dark-blue-start: #8282A2;
$gradient-dark-blue-end: #3A3B5B;

$gradient-pink-start: #FF005F;
$gradient-pink-end: #FFDAE8;

$gradient-purple-start: #7C29AC;
$gradient-purple-end: #D18BE8;

$gradient-yellow-start: #F28300;
$gradient-yellow-end: #FFC714;

$gradient-white-start: #CDD9E0;
$gradient-white-end: #FFFFFF;

$main-linear-gradient: linear-gradient(180deg, $gradient-content-start 0%, $gradient-content-end 100%);
$blue-linear-gradient: linear-gradient(180deg, $gradient-blue-start 0%, $gradient-content-end 100%);

$page-linear-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, $component-orange-color 100%);
$mascot-popup-linear-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, $color-green-bright 100%);
$badge-popup-linear-gradient: linear-gradient(180deg, $color-dark-blue-transparent 0%, $color-green-bright 100%);

$badges-linear-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, $color-red 100%);
$badge-detail-linear-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, $color-blue-dark 100%);
$component-linear-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
$linear-gradient-blue-red: linear-gradient(180deg, $color-blue-dark 0%, $color-red 100%);
$linear-gradient-yellow-red: linear-gradient(180deg, $color-yellow 0%, $color-red 100%);
$linear-gradient-green-blue-yellow:
        linear-gradient(
                        90deg,
                        $color-green-bright 10%,
                        transparent 50%
        ),
        linear-gradient(
                        195deg,
                        $color-orange 40%,
                        transparent 90%
        ),
        linear-gradient(
                        -25deg,
                        $color-blue-light 30%,
                        transparent 100%
        );;

$default-shadow: 0.2rem 0.2rem 0.6rem #00000080;
$default-shadow-no-offset: 0rem 0rem 0.6rem #00000080;
$text-shadow: .1rem .1rem .2rem #00000040;

$body-color: $color-dark-blue;
$link-color: $color-dark-blue;
$link-hover-color: $color-blue-light;

$button-size-extra-small: 3rem;
$button-size-small: 4.6rem;
$button-size: 6rem;
$button-size-large: 8rem;
$button-size-extra-large: 10rem;

$small-border-radius: .4rem;
$default-border-radius: .6rem;
$large-border-radius: 2rem;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4.5,
);

$grid-breakpoints: (
        xs: 0,
        xsm: 380px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);
$grid-gutter-width:           4rem;
$container-padding-x:         $grid-gutter-width;
$border-radius:               $default-border-radius;
$border-radius-sm:            $small-border-radius;
$border-radius-lg:            $large-border-radius;
$card-border-radius:          $border-radius-lg;
$xxl-font-size: 4.6rem;
$xl-font-size: 3.4rem;
$h1-font-size: 2.8rem;
$h2-font-size: 2.8rem;
$h3-font-size: 2.4rem;
$button-font-size: 2rem;
$large-font-size: 1.6rem;
$default-font-size: 1.4rem;
$small-font-size: 1.2rem;

$card-border-width: 0px;
$headings-font-weight: 900;
$xl-font-weight: 700;

$carousel-control-icon-width:        2.6rem;
$carousel-control-opacity:           1;
$carousel-control-hover-opacity:     .5;
$carousel-transition-duration:       .6s;
//$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.87 25.88'><defs><style>.cls-1{fill:#fff;}</style></defs><g id='Laag_2' data-name='Laag 2'><g id='Export-Englisch'><g id='NextIMG_Arrow-2'><path class='cls-1' d='M12.93,25.88A12.94,12.94,0,1,0,0,12.94,12.94,12.94,0,0,0,12.93,25.88ZM8.78,12.34,14.16,7a.84.84,0,0,1,1.44.6V18.31a.84.84,0,0,1-1.44.6L8.78,13.53A.84.84,0,0,1,8.78,12.34Z'/></g></g></g></svg>");
//$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.87 25.88'><defs><style>.cls-1{fill:#fff;}</style></defs><g id='Laag_2' data-name='Laag 2'><g id='Export-Englisch'><g id='NextIMG_Arrow'><path class='cls-1' d='M12.93,0A12.94,12.94,0,1,0,25.87,12.93,12.93,12.93,0,0,0,12.93,0Zm4.16,13.53-5.38,5.39a.85.85,0,0,1-1.44-.6V7.56A.85.85,0,0,1,11.71,7l5.38,5.38A.84.84,0,0,1,17.09,13.53Z'/></g></g></g></svg>");

//$modal-content-bg:                   black;
$modal-inner-padding:                map-get($spacers, 4);
$modal-backdrop-opacity:            .6;
$modal-content-border-radius:       0;

$zindex-loading-screen:             980 !default;
$zindex-world-ui:                   985 !default;
$zindex-content-page:               990 !default;

$nav-link-padding-x:                0;

$input-bg:                          #ffffff;
$input-placeholder-color:           #1C1C1C;
$input-font-size:                   2rem;
$input-line-height:                 1;
$input-font-weight:                 700;
//$input-border-width:                0;
$input-padding-y:                   .75rem;
$input-padding-x:                   1.75rem;
$input-border-radius:               3rem;
$input-btn-font-size:               1.2rem;
$form-label-color:                  #ffffff;
$form-label-font-weight:            700;
$form-label-font-size:              2rem;
$form-label-margin-bottom:          .75rem;
$form-floating-height:              6rem;
$form-floating-padding-x:           $input-padding-x;
$form-floating-padding-y:           $input-padding-y + 1rem;
$form-floating-label-opacity:       1;
$form-floating-label-transform:     scale(.6) translateY(-.65rem) translateX(.15rem);
$form-text-font-size:               2rem;
$form-text-font-weight:             bold;

$form-feedback-icon-valid:          none;
$form-feedback-icon-invalid:        none;

$enable-negative-margins: true;

$spinner-border-width:    .2em;

$small-screen-max: 480px;
$medium-screen-max: 768px;

$offcanvas-horizontal-width: 100vw;
$offcanvas-padding-y:        2rem;
$offcanvas-padding-x:        2rem;

$alert-padding-y:               $spacer;
$alert-padding-x:               $spacer;
$alert-margin-bottom:           1rem;
$alert-border-radius:           0;
//$alert-link-font-weight:        $font-weight-bold;
$alert-border-width:            0;
$alert-bg-scale:                -100%;
$alert-border-scale:            0%;
$alert-color-scale:             -100%;


$accordion-padding-y:                     2rem;
$accordion-padding-x:                     $grid-gutter-width * .5;
$accordion-bg:                            $color-blue-darker;
$accordion-button-bg:                     $color-blue-dark;
$accordion-button-color:                  $color-white;
$accordion-button-active-bg:              $color-blue-dark;
$accordion-button-active-color:           $color-white;
$accordion-border-width:                  0;
$accordion-icon-color:                    $color-white;
$accordion-icon-active-color:             $color-white;
$accordion-icon-width:                    2.2rem;
