@import '~@ilmiworld/ui/src/styles/variables';
@import '~@ilmiworld/ui/src/styles/animations';
@import 'Header.variables';

.component {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  min-height: $header-min-height;
  color: $color-white;
  padding: $header-padding $header-padding;
  direction: ltr; // shouldn't be flipped in arabic
}

.background {
  background-color: $color-blue-dark;
}

.spacer {
  min-height: $header-min-height;
}
