@import "~bootstrap/scss/variables";

$carousel-control-next-icon-bg-ilmi:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.1 22.2' fill='#{$carousel-control-color}'><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><path class='cls-1' d='M2,22.2A2,2,0,0,1,.59,18.79L8.27,11.1.59,3.41A2,2,0,0,1,.59.59a2,2,0,0,1,2.82,0h0l9.1,9.1a2,2,0,0,1,0,2.82l-9.1,9.1A2,2,0,0,1,2,22.2Z'/></g></g></svg>") !default;

.carousel-control-prev-icon {
    background-image: escape-svg($carousel-control-next-icon-bg-ilmi);
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.8));
    transform: rotate(180deg);
}
.carousel-control-next-icon {
    background-image: escape-svg($carousel-control-next-icon-bg-ilmi);
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.8));
}
