
@keyframes disappear {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes appear {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes appearPulse {
  from {opacity: 0.3;}
  to {opacity: 1;}
}

@keyframes appearAndDisappear {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes scaleIn {
  from {transform: scale(0);}
  to {transform: scale(1);}
}

@keyframes scaleXIn {
  from {transform: scaleX(0);}
  to {transform: scaleX(1);}
}

@keyframes scaleInOut {
  0% {transform: scale(0);}
  75% {transform: scale(2);}
  100% {transform: scale(1);}
}

@keyframes topIn {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

@keyframes bottomIn {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0.5;
    transform: scale(0.1) translate3d(0, 0, 0);
  }
  60% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  85% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes bounceLarger {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes transformYInFromTop {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes transformYInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes transformXInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
