@import '~@ilmiworld/ui/src/styles/variables';

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 200ms;

  /* correction for the 2px shadow in the icon */
  &.withShadow {
    margin-top: 2px;
    margin-right: -2px;
  }

  [dir='rtl'] & {
    &.mirror {
      transform: scaleX(-1);
      &.withShadow {
        margin-top: 2px;
        margin-right: 2px;
      }
    }
  }
}
