@import '~@ilmiworld/ui/src/styles/variables';

.irrigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;

  background-color: #141624;

  overflow: hidden;
}

.pixiTextPrep{
  font-family: 'LoewNextArabic';
  position: absolute;
  top:0px; left:0px;
  pointer-events: none;
  opacity: 0;
}
