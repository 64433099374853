@import '~bootstrap/scss/mixins';
@import "~@ilmiworld/ui/src/styles/animations";
@import "~@ilmiworld/ui/src/styles/variables";

$loading-screen-padding: 1.5rem;
.loadingScreen {
  padding: $loading-screen-padding;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:
            linear-gradient(90deg, #00b400 0%, transparent 80%),
            linear-gradient(210deg, #faa000 5%, transparent 60%),
            linear-gradient(330deg, #0096ff 5%, transparent 60%);
  }
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00b400;
  }

  &.digitalChallenge {
    &:after {
      background: $linear-gradient-blue-red;
    }
    .title {
      z-index: 2;
      background-color: $color-red;
      width: calc(100% + #{$loading-screen-padding * 2});
      margin-left: -$loading-screen-padding;
      margin-right: -$loading-screen-padding;
      padding: 2rem;
      margin-top: -3rem;
    }

    .imageContainer {
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $color-blue-dark;
    }
  }

  &.fadeOut {
    animation: disappear 750ms linear 3000ms forwards;
  }

  .illustration {
    max-width: 40rem;
    max-height: 70%;
    width: 90%;

    .image {
      width: 100%;
    }
  }

  .title {
    color: white;
    text-align: center;
    @include font-size(6.5rem);
  }

  .rotateDeviceNotice {
    max-width: 40rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
