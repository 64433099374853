@import "~@ilmiworld/ui/src/styles/variables";
@import "~bootstrap/scss/mixins.scss";
@import "~@ilmiworld/ui/src/styles/mixins";

.modal {
  &.backgroundBlur {
    backdrop-filter: blur(5px);
  }

  .fullWidthHeightFix {
    height: auto;
  }

  :global(.modal-content) {
    background: linear-gradient(45deg, $gradient-white-start 0%, $gradient-white-end 100%);
    border: none;

    :global(.modal-header) {
      background: linear-gradient(90deg, $gradient-blue-start 0%, $gradient-blue-end 100%);
      color: white;
      text-align: center;
      padding: .5rem 1.5rem;

      h1 {
        flex-grow: 1;
      }
    }

    :global(.modal-body) {
      padding: map-get($spacers, 5);
      @include customScrollbars();


      @include media-breakpoint-up(lg) {
        padding: map-get($spacers, 6);
        padding-top: map-get($spacers, 5);
      }
    }
  }

  &.error {
    :global(.modal-content) {
      :global(.modal-header) {
        background: linear-gradient(45deg, #FC004C 10%, 30%, #F70049 80%);
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32rem;
        height: 32rem;
        background: url(assets/mascot_normal.png) no-repeat;
        background-size: 100%;
        pointer-events: none;
        [dir=rtl] & {
          left: 0;
          right: auto;
          transform: scaleX(-1);
        }
      }

      @include media-breakpoint-down(lg) {
        padding-bottom: 20rem;
      }
    }
  }

  &.gradientRedBlue {
    :global(.modal-content) {
      background: $linear-gradient-blue-red;

      :global(.modal-header) {
        background: none;
        border: none;
      }
    }
  }

  &.gradientYellowRed {
    :global(.modal-content) {
      background: $linear-gradient-yellow-red;

      :global(.modal-header) {
        background: none;
        border: none;
      }
    }
  }

  &.gradientBlue {
    :global(.modal-content) {
      background: $main-linear-gradient;

      :global(.modal-header) {
        background: none;
        border: none;
      }
    }
  }

  &.red {
    :global(.modal-content) {
      background: $color-red;

      :global(.modal-header) {
        background: none;
        border: none;
      }
    }
  }

  &.blue {
    :global(.modal-content) {
      background: $color-blue-dark;

      :global(.modal-header) {
        background: none;
        border: none;
      }
    }
  }

  &.slim {
    :global(.modal-content) {
      background: none;
      border: none;

      :global(.modal-header) {
       display: none;
      }

      :global(.modal-body) {
        padding: 0;
        padding-top: 4rem;
      }
    }
  }

  &.transparent {
    :global(.modal-content) {
      background: none;
      border: none;
    }
  }


  :global(.modal-fullscreen) {
    :global(.modal-header) {

      @include media-breakpoint-up(lg) {
        padding: 4rem;
      }
    }
  }
}
