


.clockWrap{
    min-height: 4.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timeWrap{
    margin-left: 10px;
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    text-shadow: 0 0 0.5rem rgb(0 0 0 / 50%);

    display: flex;

    /*
    width: 16rem;
    margin-left: 1rem;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    */

}
.secondsWrap{
    width: 44px;
    margin-right: 5px;
    text-align: center;
    /*
    */
}